import React from 'react';
import { UsurpAd } from 'site-modules/shared/components/native-ad/srp-ad/usurp-ad';
import { SrpCardListAdWrapper } from 'site-modules/shared/components/inventory/srp-card-list-ad-wrapper/srp-card-list-ad-wrapper';
import { EASAdUnit } from 'site-modules/shared/components/eas-ad-unit/eas-ad-unit';
import { Experiment, Recipe } from 'site-modules/shared/components/experiment';
import { EasListingUsurpAd } from 'site-modules/shared/components/native-ad/eas-listing-usurp-ad/eas-listing-usurp-ad';

// MINIMUM_CARDS and first position values should be equals (ADS-5322)
export const MINIMUM_CARDS = 4;

export const UsurpAdsConfig = {
  [MINIMUM_CARDS]: {
    component(AdTargetingPrefetch, adProps) {
      return (
        <AdTargetingPrefetch key="srp-retention-4">
          <UsurpAd dco {...adProps} />
        </AdTargetingPrefetch>
      );
    },
  },
  8: {
    component(AdTargetingPrefetch, adProps) {
      return (
        <AdTargetingPrefetch key="srp-retention-7">
          <UsurpAd position={'2'} {...adProps} />
        </AdTargetingPrefetch>
      );
    },
  },
  12: {
    component(AdTargetingPrefetch, adProps) {
      return (
        <AdTargetingPrefetch key="srp-retention-11">
          <UsurpAd position={'3'} {...adProps} />
        </AdTargetingPrefetch>
      );
    },
  },
  16: {
    component(AdTargetingPrefetch, adProps) {
      return (
        <AdTargetingPrefetch key="srp-retention-15">
          <UsurpAd position={'4'} {...adProps} />
        </AdTargetingPrefetch>
      );
    },
  },
  default: {
    component(AdTargetingPrefetch, adProps) {
      return (
        <AdTargetingPrefetch key="srp-retention-7-default">
          <UsurpAd {...adProps} />
        </AdTargetingPrefetch>
      );
    },
  },
  last: {
    component(AdTargetingPrefetch) {
      return (
        <Experiment name="ads-9372" showDefault key="eas-listing-usurp-ad-ab-test">
          <Recipe name="ctrl" isDefault>
            <SrpCardListAdWrapper
              key="srp-easlisting"
              wrapperClass="easlisting-elem no-gutters d-flex col-12 col-md-6 mb-0_75 mb-md-1_5"
            >
              <EASAdUnit
                adName="easlisting"
                nativeStyle="srp"
                position={'2'}
                all
                renderWhenViewable={false}
                showLabel={false}
                refreshable
                noRefresh={['scroll', 'engagement']}
              />
            </SrpCardListAdWrapper>
          </Recipe>
          <Recipe name="chal">
            <AdTargetingPrefetch key="eas-listing-usurp-ad">
              <EasListingUsurpAd />
            </AdTargetingPrefetch>
          </Recipe>
        </Experiment>
      );
    },
  },
};
