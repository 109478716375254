import React from 'react';
import PropTypes from 'prop-types';
import { AdUnitPropTypes, AdUnitDefaultProps } from 'client/site-modules/shared/components/ad-unit/ad-unit-prop-types';

export function AdTargetingPrefetch({ children, adTargeting }) {
  return React.cloneElement(children, { ...adTargeting });
}

AdTargetingPrefetch.propTypes = {
  adTargeting: PropTypes.shape({
    currentVehicle: AdUnitPropTypes.currentVehicle,
    customTargeting: AdUnitPropTypes.customTargeting,
    dependedOnCurrentVehicle: PropTypes.bool,
  }),
  children: PropTypes.element.isRequired,
};

AdTargetingPrefetch.defaultProps = {
  adTargeting: {
    currentVehicle: AdUnitDefaultProps.currentVehicle,
    customTargeting: AdUnitDefaultProps.customTargeting,
    dependedOnCurrentVehicle: false,
  },
};
