import React, { Fragment, useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { numberWithCommas } from 'site-modules/shared/utils/string';
import { isEmpty, pick } from 'lodash';
import { srpLinkBuilder } from 'site-modules/shared/utils/srp-link-constructor';
import { replaceToSeoKeys } from 'site-modules/shared/utils/inventory/params-conversion';
import { PAGE_NUMBER as SEO_PAGE_NUMBER } from 'site-modules/shared/constants/allowed-seo-srp-request-params';
import { Pagination } from 'site-modules/shared/components/pagination/pagination';
import { PERSIST_URL_PATHNAME_FILTERS } from 'site-modules/shared/utils/inventory/search-filter';
import { PaginationLink } from 'site-modules/shared/components/pagination-link/pagination-link';
import './srp-pagination.scss';

export const SrpPagination = memo(
  ({
    selectedFacets,
    urlContext,
    pathName,
    pageNumber,
    onPageLinkClick,
    pagesCount,
    maxAllowedPageNumber,
    pageSize,
    pageResultsCount,
    inventoryCount,
    classes,
    isMobile,
  }) => {
    const pagesCountNum = maxAllowedPageNumber && pagesCount > maxAllowedPageNumber ? maxAllowedPageNumber : pagesCount;
    const totalInvCountOnPrevPages = (pageNumber - 1) * pageSize;
    const firstInvOnPageNum = numberWithCommas(totalInvCountOnPrevPages + 1);
    const lastInvOnPageNum = numberWithCommas(totalInvCountOnPrevPages + pageResultsCount);

    const getPageUrl = useCallback(
      page => {
        // For the first page, page number should not be in parameters list
        const isNumberHidden = Number(page) === 1;

        const requestParams = isEmpty(urlContext) ? selectedFacets : pick(selectedFacets, PERSIST_URL_PATHNAME_FILTERS);
        return srpLinkBuilder(
          replaceToSeoKeys({ ...requestParams, [SEO_PAGE_NUMBER]: isNumberHidden ? null : page }),
          pathName
        );
      },
      [pathName, selectedFacets, urlContext]
    );

    const numConfig = useMemo(
      () =>
        isMobile
          ? {
              allAvailablePageNum: 4,
              startNumWithoutTruncate: 4,
              middleNumWithoutTruncate: 3,
              endNumWithoutTruncate: 2,
            }
          : {
              allAvailablePageNum: 6,
              startNumWithoutTruncate: 6,
              middleNumWithoutTruncate: 5,
              numLeftOfCurrentMiddle: 2,
              endNumWithoutTruncate: 5,
              totalMiddleNumWithoutTruncate: 5,
            },
      [isMobile]
    );

    return (
      <div
        className={classNames(
          'srp-pagination row justify-content-center align-items-center flex-nowrap flex-column',
          classes,
          { desktop: !isMobile }
        )}
        data-tracking-parent="edm-entry-srp-pagination"
      >
        {pagesCount && inventoryCount ? (
          <Fragment>
            <div
              className={classNames(
                'srp-pagination-inner d-flex flex-row align-items-center flex-nowrap justify-content-around py-0_5 py-md-0_25',
                {
                  'srp-pagination-inner-mobile': isMobile,
                  clear: pagesCount === 1,
                }
              )}
            >
              <Pagination
                currentNum={pageNumber}
                totalNum={pagesCountNum}
                paginationLinkBuilder={getPageUrl}
                listClassName="d-flex justify-content-around align-items-center px-0_25 w-100"
                className="d-flex justify-content-around align-items-center"
                arrowClassName="d-flex align-items-center text-blue-30 size-16 font-weight-bold px-1 px-md-1_5 py-0_5 py-md-0_25"
                numLinkClassname="num-link text-blue-30"
                numLinkWrapperClassname={classNames('pagination-element pos-r', {
                  'mx-md-1_25': !isMobile,
                  'mx-1_25': isMobile,
                })}
                currentNumLinkClassname="font-weight-bold text-white"
                currentNumLinkWrapperClassname={classNames('pagination-element num-link-current', {
                  'mx-md-1_25': !isMobile,
                  'mx-1_25': isMobile,
                })}
                dotsClassname={classNames('dots pagination-element medium text-gray-darker', {
                  'mx-md-0_25': !isMobile,
                  'mx-0_25': isMobile,
                })}
                dataTrackingId="view_search_result"
                arrowDataTrackingId="view_search_result"
                arrowsDataTrackingValues={{
                  prev: 'previous',
                  next: 'next',
                }}
                onPageLinkClick={onPageLinkClick}
                onArrowLinkClick={onPageLinkClick}
                numConfig={numConfig}
                arrowsText={isMobile ? undefined : { right: 'Next', left: 'Previous' }}
                showDisabledArrows={pagesCountNum > 1}
                showLastNum={false}
                isTextForMobile={false}
                linkComponent={PaginationLink}
                isSrp
              />
            </div>
            <div className="mt-1 small">
              <span className="text-nowrap text-cool-gray-40">
                Showing {firstInvOnPageNum} - {lastInvOnPageNum} out of {numberWithCommas(inventoryCount)} listings
              </span>
            </div>
          </Fragment>
        ) : (
          <div className="display-6 mt-0_5">There are no pages to show</div>
        )}
      </div>
    );
  }
);

SrpPagination.propTypes = {
  pageNumber: PropTypes.number,
  pagesCount: PropTypes.number,
  maxAllowedPageNumber: PropTypes.number,
  pageResultsCount: PropTypes.number,
  pageSize: PropTypes.number,
  inventoryCount: PropTypes.number,
  selectedFacets: PropTypes.shape({}),
  urlContext: PropTypes.shape({}),
  pathName: PropTypes.string,
  classes: PropTypes.string,
  onPageLinkClick: PropTypes.func,
  isMobile: PropTypes.bool,
};

SrpPagination.defaultProps = {
  pageNumber: 1,
  pagesCount: 0,
  maxAllowedPageNumber: 0,
  pageSize: 1,
  pageResultsCount: 0,
  inventoryCount: 0,
  onPageLinkClick: null,
  selectedFacets: {},
  urlContext: {},
  pathName: '',
  classes: '',
  isMobile: false,
};
