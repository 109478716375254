import React from 'react';
import PropTypes from 'prop-types';

export function PaginationLink(props) {
  const { to, children, ...rest } = props;
  return (
    <a {...(to !== null ? { href: to } : {})} {...rest}>
      {children}
    </a>
  );
}

PaginationLink.propTypes = {
  to: PropTypes.string,
};

PaginationLink.defaultProps = {
  to: null,
};
