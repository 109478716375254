import * as SEO_PARAMS from 'client/site-modules/shared/constants/allowed-seo-srp-request-params';
import * as FACET_PARAMS from 'client/site-modules/shared/constants/allowed-inventory-request-params';

/**
 *
 * The map of allowed parameters what used for IDM save search
 *
 * NOTE: Parameters saved to IDM generally match:
 * `allowed-seo-srp-request-params.js` and
 * `allowed-inventory-request-params.js`
 * @property {String} path - property path of the searchResults.attributes
 * @property {String} name - property name of the IDM searchQuery
 */
export const SAVE_SEARCH_MAPS = {
  [FACET_PARAMS.CYLINDERS_COUNT]: {
    path: '',
    name: SEO_PARAMS.CYLINDERS_COUNT,
  },
  [FACET_PARAMS.DEAL_TYPE]: {
    path: '',
    name: SEO_PARAMS.DEAL_TYPE,
  },
  [FACET_PARAMS.DISPLAY_PRICE]: {
    path: '',
    name: SEO_PARAMS.DISPLAY_PRICE,
  },
  [FACET_PARAMS.DRIVE_TRAIN]: {
    path: '',
    name: SEO_PARAMS.DRIVE_TRAIN,
  },
  [FACET_PARAMS.EXTERIOR_COLOR]: {
    path: 'exteriorColor',
    name: SEO_PARAMS.EXTERIOR_COLOR,
  },
  [FACET_PARAMS.FEATURE]: {
    path: '',
    name: SEO_PARAMS.FEATURE,
  },
  [FACET_PARAMS.FUEL_TYPE]: {
    path: '',
    name: SEO_PARAMS.FUEL_TYPE,
  },
  [FACET_PARAMS.HISTORY_INFO]: {
    path: '',
    name: SEO_PARAMS.HISTORY_INFO,
  },
  [FACET_PARAMS.INTERIOR_COLOR]: {
    path: 'interiorColor',
    name: SEO_PARAMS.INTERIOR_COLOR,
  },
  [FACET_PARAMS.INVENTORY_TYPE]: {
    path: 'inventoryType',
    name: SEO_PARAMS.INVENTORY_TYPE,
  },
  [FACET_PARAMS.LEASE_PAYMENT]: {
    path: '',
    name: SEO_PARAMS.LEASE_PAYMENT,
  },
  [FACET_PARAMS.LOAN_PAYMENT]: {
    path: '',
    name: SEO_PARAMS.LOAN_PAYMENT,
  },
  [FACET_PARAMS.MAKE]: {
    path: 'vehicle.make.name',
    name: SEO_PARAMS.MAKE,
  },
  [FACET_PARAMS.MILEAGE]: {
    path: '',
    name: SEO_PARAMS.MILEAGE,
  },
  [FACET_PARAMS.MODEL]: {
    path: 'vehicle.model.name',
    name: SEO_PARAMS.MODEL,
  },
  [FACET_PARAMS.NATIONAL_INVENTORY]: {
    path: '',
    name: SEO_PARAMS.NATIONAL_INVENTORY,
  },
  [FACET_PARAMS.OPTION]: {
    path: '',
    name: SEO_PARAMS.OPTION,
  },
  [FACET_PARAMS.PAYMENT_TYPE]: {
    path: 'paymentType',
    name: SEO_PARAMS.PAYMENT_TYPE,
  },
  [FACET_PARAMS.RADIUS]: {
    path: 'radius',
    name: SEO_PARAMS.RADIUS,
  },
  [FACET_PARAMS.ROOFTOP_ID]: {
    path: '',
    name: SEO_PARAMS.ROOFTOP_ID,
  },
  [FACET_PARAMS.SORT_BY]: {
    path: '',
    name: SEO_PARAMS.SORT_BY,
  },
  [FACET_PARAMS.TRANSMISSION]: {
    path: '',
    name: SEO_PARAMS.TRANSMISSION,
  },
  [FACET_PARAMS.TRIM]: {
    path: '',
    name: SEO_PARAMS.TRIM,
  },
  [FACET_PARAMS.YEAR]: {
    path: '',
    name: SEO_PARAMS.YEAR,
  },
  [FACET_PARAMS.DELIVERY_TYPE]: {
    path: '',
    name: SEO_PARAMS.DELIVERY_TYPE,
  },
  [FACET_PARAMS.RECENTLY_LISTINGS]: {
    path: '',
    name: SEO_PARAMS.RECENTLY_LISTINGS,
  },
  [FACET_PARAMS.PRICE_DROP]: {
    path: '',
    name: SEO_PARAMS.PRICE_DROP,
  },
  [FACET_PARAMS.WITH_PHOTO]: {
    path: '',
    name: SEO_PARAMS.WITH_PHOTO,
  },
  [FACET_PARAMS.WITH_PRICE]: {
    path: '',
    name: SEO_PARAMS.WITH_PRICE,
  },
  [FACET_PARAMS.EXCLUDE_IN_TRANSIT]: {
    path: '',
    name: SEO_PARAMS.EXCLUDE_IN_TRANSIT,
  },
  [FACET_PARAMS.ENGINE_TYPE]: {
    path: '',
    name: SEO_PARAMS.ENGINE_TYPE,
  },
  [FACET_PARAMS.COMBINE_MPG]: {
    path: '',
    name: SEO_PARAMS.COMBINE_MPG,
  },
  [FACET_PARAMS.ELECTRICITY_RANGE]: {
    path: '',
    name: SEO_PARAMS.ELECTRICITY_RANGE,
  },
  [FACET_PARAMS.BATTERY_RANGE_SCORE]: {
    path: '',
    name: SEO_PARAMS.BATTERY_RANGE_SCORE,
  },
  [FACET_PARAMS.BODY_TYPE]: {
    path: '',
    name: SEO_PARAMS.BODY_TYPE,
  },
  [FACET_PARAMS.TRUCK_CAB_SIZE]: {
    path: '',
    name: SEO_PARAMS.TRUCK_CAB_SIZE,
  },
  [FACET_PARAMS.BED_LENGTH]: {
    path: '',
    name: SEO_PARAMS.BED_LENGTH,
  },
  [FACET_PARAMS.DISPLACEMENT]: {
    path: '',
    name: SEO_PARAMS.DISPLACEMENT,
  },
  [FACET_PARAMS.REAR_WHEELS]: {
    path: '',
    name: SEO_PARAMS.REAR_WHEELS,
  },
  [FACET_PARAMS.SELF_DRIVING_LEVEL]: {
    path: '',
    name: SEO_PARAMS.SELF_DRIVING_LEVEL,
  },
  [FACET_PARAMS.COMFORT_CONVENIENCE]: {
    path: '',
    name: SEO_PARAMS.COMFORT_CONVENIENCE,
  },
  [FACET_PARAMS.SAFETY]: {
    path: '',
    name: SEO_PARAMS.SAFETY,
  },
  [FACET_PARAMS.ENGINE_EXTERIOR]: {
    path: '',
    name: SEO_PARAMS.ENGINE_EXTERIOR,
  },
  [FACET_PARAMS.ENTERTAINMENT]: {
    path: '',
    name: SEO_PARAMS.ENTERTAINMENT,
  },
  [FACET_PARAMS.HOME_DELIVERY]: {
    path: '',
    name: SEO_PARAMS.HOME_DELIVERY,
  },
  [FACET_PARAMS.TOTAL_SEATING]: {
    path: '',
    name: SEO_PARAMS.TOTAL_SEATING,
  },
};
