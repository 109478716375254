import uuid from 'uuid';
import { get, isEmpty, isEqual } from 'lodash';
import { getSyncDate } from 'site-modules/shared/components/profile/idm/idm-utils';
import { SAVE_SEARCH_MAPS } from 'site-modules/shared/constants/inventory/save-search-map';

/**
 * getSaveSearchImage - get save search image
 * @param {Object} vehicle
 * @returns {string}
 */
export function getSaveSearchImage({ stockPhotoInfo }) {
  return get(stockPhotoInfo, 'photos[0].url');
}

/**
 * getSaveSearchName - get save search name
 * @param {Object} attributes
 * @param {String} zipCode
 * @returns {string}
 */
export function getSaveSearchName({ vehicle = {}, type }, zipCode) {
  let title = 'Cars';
  const make = vehicle.make && vehicle.make.name;
  const model = vehicle.model && vehicle.model.name;

  if (make && model) {
    title = `${make} ${model}`;
  } else if (make) {
    title = make;
  } else if (type) {
    title = type;
  }

  return `${title} for Sale Near ${zipCode}`;
}

/**
 * getSearchQuery - get save search searchQuery
 * @param {Object} attributes
 * @param {Object} selectedFacets
 * @param {String} zipCode
 * @returns {Object}
 */
export function getCurrentSearchQuery(attributes, selectedFacets, zipCode) {
  /**
   * set up default properties value of the searchQuery the same as the groundwork
   * @property {String} radius - default value of the attributes.radius
   * @property {String} zip - default value of the visitorLocation.zipCode
   */
  const searchQuery = {
    radius: `${attributes.radius}`,
    zip: zipCode,
  };

  /**
   * set up properties value of the searchQuery
   * @property {String}
   */
  if (!isEmpty(selectedFacets)) {
    Object.keys(selectedFacets).forEach(facet => {
      const activeFacet = SAVE_SEARCH_MAPS[facet];
      if (activeFacet) {
        // name - property name of the IDM searchQuery
        const { name } = activeFacet;
        searchQuery[name] = selectedFacets[facet].toString();
      }
    });
  }

  return searchQuery;
}

/**
 * Prepares new saveSearch that contains all properties
 * needed to save a record about a search you're interested in to the IDM
 * @param {Object} searchResults
 * @param {Object} selectedFacets
 * @param {String} zipCode
 * @returns {Object}
 */
export function getCurrentSaveSearch({ searchResults, selectedFacets, zipCode }) {
  const { inventories = {}, attributes = {} } = searchResults || {};
  const vehicle = (inventories && inventories.results && inventories.results[0]) || {};
  const imageUrl = getSaveSearchImage(vehicle);
  const ts = getSyncDate();
  const saveSearch = {
    createTs: ts,
    id: uuid.v4(),
    name: getSaveSearchName(attributes, zipCode),
    searchQuery: getCurrentSearchQuery(attributes, selectedFacets, zipCode),
    updateTs: ts,
  };

  if (imageUrl) saveSearch.imageUrl = imageUrl;

  return saveSearch;
}

/**
 * get the saveSearch from the given savedSearches array.
 * @param {Object} props
 * @return {Object} saveSearch
 */
export function getActiveSaveSearch(props) {
  const { savedSearches } = props;
  const { searchQuery } = getCurrentSaveSearch(props);
  return savedSearches.find(ss => isEqual(searchQuery, ss.searchQuery));
}
