import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { get, sortBy } from 'lodash';

import { SORT_BY } from 'site-modules/shared/constants/allowed-inventory-request-params';
import {
  BEST_MATCH_VALUE,
  SORT_BY_FILTER_BOL,
  SORT_BY_FILTER_SRP,
  SORT_BY_MILEAGE,
  SORT_BY_RANGE,
} from 'site-modules/shared/constants/inventory/static-facets';
import { isUsedInventoryTypeSelected } from 'site-modules/shared/utils/inventory/srp-utils';
import { Select } from 'site-modules/shared/components/select/select';

import './srp-sort-by.scss';

function getSortByFromProps(facets) {
  return get(facets, `[${SORT_BY}][0]`);
}

/**
 * SRP Sort By visitor choice (best deals, price, etc.).
 */
export function SrpSortBy({ onUpdate, selectedFacets, withRange }) {
  const [selected, setSelected] = useState(getSortByFromProps(selectedFacets));
  const isBuyOnline = get(selectedFacets, 'isBuyOnline', false);
  const SORT_BY_FILTER = isBuyOnline ? SORT_BY_FILTER_BOL : SORT_BY_FILTER_SRP;
  const SORT_BY_FILTER_EXTENDED = {
    ...SORT_BY_FILTER,
    values: [...SORT_BY_FILTER.values, ...SORT_BY_MILEAGE],
  };

  useEffect(() => {
    const selectedByDefault = get(SORT_BY_FILTER_EXTENDED.values.find(item => item.selected), 'value');
    const selectedSortBy = getSortByFromProps(selectedFacets);

    setSelected(selectedSortBy || selectedByDefault);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFacets]);

  const handleChange = useCallback(
    option => {
      setSelected(get(option, 'value'));
      onUpdate(SORT_BY, option, isBuyOnline || get(option, 'value') !== BEST_MATCH_VALUE);
    },
    [onUpdate, isBuyOnline]
  );

  let options = (isUsedInventoryTypeSelected(selectedFacets) || isBuyOnline ? SORT_BY_FILTER_EXTENDED : SORT_BY_FILTER)
    .values;

  if (withRange) {
    options = [...options, ...SORT_BY_RANGE];
  }
  const rankedOptions = sortBy(options, ['displayRank']);

  return (
    <div className="srp-sort-by size-14 pos-r" data-tracking-parent="inventory_sort">
      <Select
        id="sort-by"
        name="sort-by"
        className="pos-a top-0 left-0 w-100 h-100"
        defaultValue={{}}
        valueKey="value"
        labelKey="name"
        value={selected}
        options={rankedOptions}
        isFirstOptionDisabled={false}
        onChange={handleChange}
        data-tracking-id="select_sort_by"
      />
      <label htmlFor="sort-by" className="pos-r m-0">
        <span className="text-blue-30">
          Sort by: <i className="icon-sort align-middle" aria-hidden />
        </span>
      </label>
    </div>
  );
}

SrpSortBy.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  selectedFacets: PropTypes.shape({}),
  withRange: PropTypes.bool,
};

SrpSortBy.defaultProps = {
  selectedFacets: {},
  withRange: false,
};
