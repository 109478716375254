import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import { AdUnit } from 'site-modules/shared/components/ad-unit/ad-unit';

/**
 * Wrapper component for a Google Native AdUnit that informs the ad's iframe of the window width.
 */
export class EASAdUnitUI extends Component {
  componentDidMount() {
    window.addEventListener('message', this.resizeIframeHeight);
  }

  shouldComponentUpdate(nextProps) {
    return !isEqual(nextProps, this.props);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.resizeIframeHeight);
  }

  wrapper = createRef();

  /**
   * Resize ad iframe height based on received message.
   * Message data type should be unique to a type of ad as we cannot differentiate between
   * message sources with ad iframes. Resize will happen on any ad in this wrapper.
   */
  resizeIframeHeight = event => {
    const iFrame = this.wrapper.current.querySelector('iframe');
    if (iFrame && event.data && event.data.type === 'eas-ad-height-change') {
      const wrapElem = this.wrapper.current.closest('.easlisting-elem');
      const closestVehicleCardHeight = wrapElem ? wrapElem.clientHeight : 0;
      const frameHeight = event.data.value;
      const height = Math.max(frameHeight, closestVehicleCardHeight);
      iFrame.style.height = `${height}px`;
      iFrame.style.width = '100%';
    }
  };

  render() {
    return <div ref={this.wrapper}>{this.props.isLoaded && <AdUnit {...this.props} isIncrementPosition />}</div>;
  }
}

export const mapStateToProps = state => ({
  isLoaded: get(state, 'pageContext.loading.isLoaded'),
});

export const EASAdUnit = connect(mapStateToProps)(EASAdUnitUI);
