import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { isNumber, get, noop, isEmpty } from 'lodash';
import { Link } from 'site-modules/shared/components/link/link';
import { PaginationLink } from 'site-modules/shared/components/pagination-link/pagination-link';

import './pagination.scss';

const TOTAL_NUM_WITHOUT_TRUNCATE = 5;
const TOTAL_MIDDLE_NUM_WITHOUT_TRUNCATE = 3;
const DIRECTION_NEXT = 'right';
const DIRECTION_PREVIOUS = 'left';

export class PaginationUI extends Component {
  static propTypes = {
    currentNum: PropTypes.number.isRequired,
    totalNum: PropTypes.number.isRequired,
    paginationLinkBuilder: PropTypes.func,
    isMobile: PropTypes.bool,
    noFollow: PropTypes.bool,
    trackingIdPrefix: PropTypes.string,
    className: PropTypes.string,
    arrowsText: PropTypes.shape({
      right: PropTypes.string,
      left: PropTypes.string,
    }),
    showDisabledArrows: PropTypes.bool,
    onPageLinkClick: PropTypes.func,
    onArrowLinkClick: PropTypes.func,
    numConfig: PropTypes.shape({
      allAvailablePageNum: PropTypes.number,
      startNumWithoutTruncate: PropTypes.number,
      middleNumWithoutTruncate: PropTypes.number,
      endNumWithoutTruncate: PropTypes.number,
    }),
    arrowClassName: PropTypes.string,
    numLinkClassname: PropTypes.string,
    numLinkWrapperClassname: PropTypes.string,
    currentNumLinkClassname: PropTypes.string,
    currentNumLinkWrapperClassname: PropTypes.string,
    dotsClassname: PropTypes.string,
    listClassName: PropTypes.string,
    dataTrackingId: PropTypes.string,
    arrowDataTrackingId: PropTypes.string,
    showLastNum: PropTypes.bool,
    isTextForMobile: PropTypes.bool,
    isSrp: PropTypes.bool,
    linkComponent: PropTypes.elementType, // TODO: shop-1929 cleanup - revert this so it is no longer passed in, but always the same (regular a tag).
    arrowsDataTrackingValues: PropTypes.shape({
      next: PropTypes.string,
      prev: PropTypes.string,
    }),
    creativeId: PropTypes.string,
    navAriaLabel: PropTypes.string,
  };

  static defaultProps = {
    paginationLinkBuilder: noop,
    isMobile: true,
    noFollow: false,
    trackingIdPrefix: null,
    className: null,
    arrowsText: null,
    showDisabledArrows: false,
    onPageLinkClick: null,
    onArrowLinkClick: null,
    numConfig: {
      allAvailablePageNum: TOTAL_NUM_WITHOUT_TRUNCATE,
      startNumWithoutTruncate: TOTAL_NUM_WITHOUT_TRUNCATE,
      middleNumWithoutTruncate: TOTAL_NUM_WITHOUT_TRUNCATE,
      endNumWithoutTruncate: TOTAL_NUM_WITHOUT_TRUNCATE,
    },
    arrowClassName: 'text-primary-darker size-10 px-0_5',
    numLinkClassname: 'px-0_5 size-12',
    numLinkWrapperClassname: '',
    currentNumLinkClassname: 'font-weight-bold px-0_5 size-12',
    currentNumLinkWrapperClassname: '',
    dotsClassname: 'font-weight-bold px-0_25 size-12',
    listClassName: '',
    dataTrackingId: null,
    arrowDataTrackingId: null,
    showLastNum: true,
    isTextForMobile: true,
    isSrp: false,
    linkComponent: Link,
    arrowsDataTrackingValues: null,
    creativeId: undefined,
    navAriaLabel: 'Pagination Navigation',
  };

  getArrowLink = direction => {
    const {
      arrowsText,
      currentNum,
      totalNum,
      onArrowLinkClick,
      paginationLinkBuilder,
      trackingIdPrefix,
      arrowDataTrackingId,
      arrowClassName,
      linkComponent: LinkComponent,
      arrowsDataTrackingValues,
      creativeId,
    } = this.props;
    const isDirectionNext = direction === DIRECTION_NEXT;
    const isDisabled = (isDirectionNext && currentNum >= totalNum) || (!isDirectionNext && currentNum === 1);
    const nextNum = isDirectionNext ? currentNum + 1 : currentNum - 1;
    const link = paginationLinkBuilder(nextNum);
    const handleArrowLinkClick = onArrowLinkClick ? this.handleLinkClick(onArrowLinkClick, nextNum) : null;

    const nextText = get(arrowsText, DIRECTION_NEXT, '');
    const prevText = get(arrowsText, DIRECTION_PREVIOUS, '');

    let trackingValue;

    if (arrowDataTrackingId) {
      if (isEmpty(arrowsDataTrackingValues)) {
        trackingValue = nextNum;
      } else {
        trackingValue = isDirectionNext ? arrowsDataTrackingValues.next : arrowsDataTrackingValues.prev;
      }
    }

    const linkContent = (
      <Fragment>
        <span className="hidden-sm-down">{isDirectionNext && nextText}</span>
        <i
          className={classnames(`icon-arrow-${direction}4`, {
            'ml-0_5': isDirectionNext && nextText,
            'mr-0_5': !isDirectionNext && prevText,
          })}
          aria-hidden
        />
        <span className="hidden-sm-down">{!isDirectionNext && prevText}</span>
      </Fragment>
    );

    return isDisabled ? (
      <PaginationLink
        className={classnames('arrow-link disabled', arrowClassName)}
        role="link"
        aria-disabled="true"
        aria-label={`No ${isDirectionNext ? 'next' : 'previous'} page`}
      >
        {linkContent}
      </PaginationLink>
    ) : (
      <LinkComponent
        className={classnames('arrow-link', arrowClassName)}
        to={link}
        rel="nofollow"
        aria-label={`Go to the ${isDirectionNext ? 'next' : 'previous'} page`}
        data-tracking-id={
          trackingIdPrefix ? `${trackingIdPrefix}_${isDirectionNext ? 'next' : 'back'}` : arrowDataTrackingId
        }
        data-tracking-value={trackingValue}
        data-tracking-parent={creativeId}
        onClick={handleArrowLinkClick}
      >
        {linkContent}
      </LinkComponent>
    );
  };

  getNumLink = (num, keyProp = '') => {
    const {
      currentNum,
      paginationLinkBuilder,
      noFollow,
      trackingIdPrefix,
      onPageLinkClick,
      numLinkClassname,
      numLinkWrapperClassname,
      currentNumLinkClassname,
      currentNumLinkWrapperClassname,
      dotsClassname,
      dataTrackingId,
      linkComponent: LinkComponent,
      creativeId,
    } = this.props;
    const key = `page-link-${num}-${keyProp}`;
    const handlePageLinkClick = onPageLinkClick ? this.handleLinkClick(onPageLinkClick, +num) : null;

    return num === currentNum || !isNumber(num) ? (
      <li key={key} className={classnames({ [currentNumLinkWrapperClassname]: num === currentNum })}>
        <PaginationLink
          className={classnames('disabled', num === currentNum ? currentNumLinkClassname : dotsClassname)}
          role="link"
          {...(num === currentNum ? { 'aria-current': true } : {})}
          aria-disabled="true"
        >
          {num}
        </PaginationLink>
      </li>
    ) : (
      <li key={key} className={numLinkWrapperClassname}>
        <LinkComponent
          className={numLinkClassname}
          to={paginationLinkBuilder(num)}
          rel={noFollow ? 'nofollow' : undefined}
          aria-label={`Go to page #${num}`}
          data-tracking-id={trackingIdPrefix ? `${trackingIdPrefix}_to` : dataTrackingId}
          onClick={handlePageLinkClick}
          data-tracking-parent={creativeId}
        >
          {num}
        </LinkComponent>
      </li>
    );
  };

  getPaginationLinks = () => {
    const { currentNum, totalNum, numConfig, showLastNum, isSrp } = this.props;
    const {
      allAvailablePageNum,
      startNumWithoutTruncate,
      middleNumWithoutTruncate,
      endNumWithoutTruncate,
      totalMiddleNumWithoutTruncate,
      numLeftOfCurrentMiddle,
    } = numConfig;
    const paginationLinks = [];

    if (totalNum <= allAvailablePageNum) {
      for (let num = 1; num <= totalNum; num += 1) {
        paginationLinks.push(this.getNumLink(num));
      }

      return paginationLinks;
    }

    if (currentNum > middleNumWithoutTruncate && currentNum < totalNum - endNumWithoutTruncate) {
      paginationLinks.push(this.getNumLink(1));
      paginationLinks.push(this.getNumLink('...', 1));

      const leftNum = numLeftOfCurrentMiddle || 1;
      const startNum = currentNum - leftNum;
      const endNum =
        showLastNum && startNum + middleNumWithoutTruncate < totalNum
          ? middleNumWithoutTruncate
          : totalMiddleNumWithoutTruncate || TOTAL_MIDDLE_NUM_WITHOUT_TRUNCATE;
      for (let index = 0; index < endNum; index += 1) {
        paginationLinks.push(this.getNumLink(startNum + index));
      }

      if (!isSrp) {
        paginationLinks.push(this.getNumLink('...', 2));
      }

      if (showLastNum) {
        paginationLinks.push(this.getNumLink(totalNum));
      }

      return paginationLinks;
    }

    if (currentNum <= startNumWithoutTruncate) {
      for (let num = 1; num <= startNumWithoutTruncate; num += 1) {
        paginationLinks.push(this.getNumLink(num));
      }

      if (!isSrp) {
        paginationLinks.push(this.getNumLink('...', 1));
      }
      if (showLastNum) {
        paginationLinks.push(this.getNumLink(totalNum));
      }

      return paginationLinks;
    }

    paginationLinks.push(this.getNumLink(1));
    paginationLinks.push(this.getNumLink('...', 1));
    for (let num = totalNum - endNumWithoutTruncate; num <= totalNum; num += 1) {
      paginationLinks.push(this.getNumLink(num));
    }

    return paginationLinks;
  };
  handleLinkClick = (func, num) => e => func(e, num);

  render() {
    const {
      currentNum,
      totalNum,
      isMobile,
      className,
      showDisabledArrows,
      isTextForMobile,
      listClassName,
      navAriaLabel,
    } = this.props;

    return (
      <nav
        className={classnames('pagination-component text-center text-gray-darker', className)}
        aria-label={navAriaLabel}
      >
        <ul className={classnames('list-unstyled mb-0', listClassName)}>
          {(currentNum > 1 || showDisabledArrows) && <li>{this.getArrowLink(DIRECTION_PREVIOUS)}</li>}
          {isMobile && isTextForMobile ? (
            <li className="size-12">
              Page {currentNum} of {totalNum}
            </li>
          ) : (
            this.getPaginationLinks()
          )}
          {(currentNum < totalNum || showDisabledArrows) && <li>{this.getArrowLink(DIRECTION_NEXT)}</li>}
        </ul>{' '}
      </nav>
    );
  }
}

export const mapStateToProps = state => ({
  isMobile: state.mobile,
});

export const Pagination = connect(mapStateToProps)(PaginationUI);
