export const usurpSsaStl = {
  usurpSsa: {
    boxSizing: 'border-box',
    minHeight: '316px',
    overflow: 'hidden',
  },
  oemLogoTopLeft: {
    maxHeight: '30px',
    position: 'absolute',
    top: '0.25rem',
    left: '0.5rem',
  },
  conquestTopLeft: {
    height: '20px',
    position: 'absolute',
    top: '8px',
    left: '0',
  },
  oemLogo: {
    maxHeight: '30px',
  },
  jellyBean: {
    width: '80%',
    maxWidth: '350px',
    height: '184px',
    overflow: 'hidden',
  },
  img: {
    position: 'absolute',
    left: '0',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '100%',
  },
  persistentDisclaimer: {
    maxHeight: '32px',
    lineHeight: '1rem',
    overflow: 'hidden',
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  headline: {
    lineHeight: '1.25rem',
    minHeight: '1.25rem',
  },
  ul: {
    paddingLeft: 0,
    listStyleType: 'none',
  },
  borderLeft: {
    borderLeft: '1px solid #e6e6e6',
  },
  borderBottom: {
    borderBottom: '1px solid #e6e6e6',
  },
};
