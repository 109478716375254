import React, { Fragment, useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { clone, compact, map, set, merge, uniqBy, omit, noop } from 'lodash';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { InventoryEntities } from 'client/data/models/inventory';
import { MinHeightWrapper } from 'site-modules/shared/components/min-height-wrapper/min-height-wrapper';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { Link } from 'site-modules/shared/components/link/link';
import { getSrpBrowserQuery } from 'site-modules/shared/utils/inventory/srp-utils';
import { SRP_BASE_PATHNAME } from 'site-modules/shared/utils/srp-link-constructor';
import searchIllustration from 'client/images/search-illustration.svg';
import { scrollToHash } from 'client/utils/scroll';
import { SPR_SCROLL_TO_TOP_ANCHOR } from 'site-modules/shared/constants/inventory/routes-constants';
import { LowInventoryLeadFormAsync } from 'site-modules/shared/components/inventory/low-inventory-lead-form/low-inventory-lead-form-async';
import { ZipWithDefaultActionHandling } from 'site-modules/shared/components/zip-with-default-action-handling/zip-with-default-action-handling';
import { LINK_INFO_MAPPING_WITH_TYPE } from 'site-modules/shared/components/inventory/constants/inventories-not-found-with-links';

import './inventories-not-found-with-links.scss';

const CREATIVE_ID = 'expand_search_options';

export const InventoriesNotFoundWithLinks = memo(
  ({
    attributes,
    inventoriesCount,
    isNoInventory,
    withLeadForm,
    basePathname,
    selectedFacets,
    mapping,
    onZipCodeUpdate,
    onUpdate,
  }) => {
    const [links, setLinks] = useState([]);
    const [isLeadFormSubmitted, setIsLeadFormSubmitted] = useState(false);
    const make = attributes?.vehicle?.make?.name ?? '';
    const model = attributes?.vehicle?.model?.name ?? '';
    const isLowInventoryLeadForm = withLeadForm && !!attributes?.lowInventoryLeadForm && !!attributes?.vehicle;

    const onLeadFormSubmit = () => setIsLeadFormSubmitted(true);

    const onZipChange = zipCode => {
      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
        event_data: {
          action_name: TrackingConstant.ACTION_PROVIDE_ZIP_CODE,
          subaction_name: TrackingConstant.ACTION_PROVIDE_ZIP_CODE,
          creative_id: CREATIVE_ID,
          action_category: TrackingConstant.USER_ACTION_CATEGORY,
          action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
          value: zipCode,
        },
      });
      onZipCodeUpdate(zipCode);
    };

    const scrollToTop = () => {
      scrollToHash(`#${SPR_SCROLL_TO_TOP_ANCHOR}`, false);
    };

    const clearAllFilters = () => {
      scrollToTop();
      onUpdate();
    };

    useEffect(() => {
      setLinks(
        compact(
          uniqBy(
            map(selectedFacets, (value, facet) => {
              const linkInfo = clone(mapping[facet]);
              if (!linkInfo || (linkInfo.hiddenRule && linkInfo.hiddenRule(value, selectedFacets))) return false;
              if (linkInfo.handler) {
                merge(linkInfo, linkInfo.handler(facet, value, selectedFacets));
              } else {
                set(linkInfo, 'query', getSrpBrowserQuery(omit(selectedFacets, facet)));
              }

              return linkInfo;
            }),
            'text'
          )
        )
      );
    }, [mapping, selectedFacets]);

    return (
      <div
        className={classnames('search-wrapper my-1', {
          'with-lead-form py-2 px-0_5': isLowInventoryLeadForm,
          'bg-blue-90 overlay-white-40': !isLeadFormSubmitted && isLowInventoryLeadForm,
          'bg-white': isLeadFormSubmitted,
        })}
        data-tracking-parent={CREATIVE_ID}
      >
        {!isLowInventoryLeadForm && (
          <div className="text-center">
            {inventoriesCount ? (
              <p className="matches-heading heading-3 mx-auto mb-2">
                We didn’t find many exact matches. Try adjusting your filters
              </p>
            ) : (
              <Fragment>
                <img
                  height={120}
                  width={204}
                  src={searchIllustration}
                  alt={'No matching inventory found.'}
                  loading="lazy"
                />
                <p className="matches-heading heading-3 mx-auto mb-2">
                  We didn’t find any exact matches. Try adjusting your filters.
                </p>
              </Fragment>
            )}
          </div>
        )}
        {isLowInventoryLeadForm && (
          <Fragment>
            {!isNoInventory && !isLeadFormSubmitted && (
              <div
                className="heading-4 text-center mb-2"
                style={{
                  fontSize: '1.25rem',
                  lineHeight: '1.5rem',
                  letterSpacing: '0',
                  fontWeight: '700',
                  marginBottom: '2rem',
                }}
              >
                We didn’t find {inventoriesCount ? 'many' : 'any'} exact matches near{' '}
                <ZipWithDefaultActionHandling
                  onChange={onZipChange}
                  buttonClassName="color-primary-darker text-underline size-20"
                  buttonTrackingId="provide_zip_code"
                />
              </div>
            )}
            <MinHeightWrapper
              componentType="LOW_INVENTORY_LEAD_FORM"
              className={classnames('bg-white py-1 mx-auto', { 'lead-form-card px-1 px-md-2': !isLeadFormSubmitted })}
            >
              <LowInventoryLeadFormAsync
                vehicleParams={attributes.vehicle}
                onSubmit={onLeadFormSubmit}
                isNoInventory={isNoInventory}
              />
            </MinHeightWrapper>
          </Fragment>
        )}
        {!isLeadFormSubmitted && (
          <div
            className={classnames('search-links py-2', {
              'bg-blue-90 overlay-white-40 px-0_5': !isLowInventoryLeadForm,
            })}
          >
            <p className="interested-heading heading-5 mb-0_5 mx-auto text-center">
              {isLowInventoryLeadForm ? (
                <Fragment>Or try adjusting your filters:</Fragment>
              ) : (
                <Fragment>
                  You may be interested in one of the following {make} {model} searches:
                </Fragment>
              )}
            </p>
            <div className="links-list-wrapper mx-auto">
              <Row tag="ul" className="search-links-list list-unstyled mb-0">
                <Col xs={12} md={6} tag="li" className="link-box mt-1 px-0_75">
                  <button
                    onClick={clearAllFilters}
                    className="link-el w-100 bg-white mx-auto d-flex align-items-center justify-content-between py-0_75 px-1 text-gray-darker font-weight-medium text-decoration-none"
                    data-tracking-id="inventory_view_filter_search_result"
                  >
                    <div className="d-flex align-items-center">
                      <div className="link-icon rounded-circle d-flex justify-content-center align-items-center text-blue-50 bg-blue-100 mr-0_75">
                        <i className="icon icon-cross2" aria-hidden />
                      </div>
                      <span className="text-decoration-none">Clear all filters</span>
                    </div>
                    <i className="size-14 icon icon-arrow-right3 text-blue-30" aria-hidden />
                  </button>
                </Col>
                {links.map(({ query, trackingValue, text, icon }) => (
                  <Col xs={12} md={6} tag="li" className="link-box mt-1 px-0_75" key={query}>
                    <Link
                      className="link-el bg-white mx-auto d-flex align-items-center justify-content-between py-0_75 px-1 text-gray-darker font-weight-medium text-decoration-none"
                      rel="nofollow"
                      to={`${basePathname}${query ? '?' : ''}${query}`}
                      data-tracking-id="inventory_view_filter_search_result"
                      data-tracking-value={trackingValue}
                      onClick={scrollToTop}
                    >
                      <div className="d-flex align-items-center">
                        <div className="link-icon rounded-circle d-flex justify-content-center align-items-center text-blue-50 bg-blue-100 mr-0_75">
                          <i className={classnames('icon', icon)} />
                        </div>
                        <span>{text}</span>
                      </div>
                      <i className="size-14 icon icon-arrow-right3 text-blue-30" aria-hidden />
                    </Link>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        )}
      </div>
    );
  }
);

InventoriesNotFoundWithLinks.propTypes = {
  selectedFacets: PropTypes.shape({}),
  attributes: InventoryEntities.Attributes,
  inventoriesCount: PropTypes.number,
  onUpdate: PropTypes.func.isRequired,
  onZipCodeUpdate: PropTypes.func,
  isNoInventory: PropTypes.bool,
  withLeadForm: PropTypes.bool,
  mapping: PropTypes.objectOf(
    PropTypes.shape({
      text: PropTypes.string,
      trackingValue: PropTypes.string,
      icon: PropTypes.string,
      handler: PropTypes.func,
      hiddenRule: PropTypes.func,
    })
  ),
  basePathname: PropTypes.string,
};
InventoriesNotFoundWithLinks.defaultProps = {
  selectedFacets: {},
  attributes: {},
  inventoriesCount: 0,
  onZipCodeUpdate: noop,
  isNoInventory: false,
  withLeadForm: false,
  mapping: LINK_INFO_MAPPING_WITH_TYPE,
  basePathname: SRP_BASE_PATHNAME,
};
