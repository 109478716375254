import {
  BODY_TYPE,
  CYLINDERS_COUNT,
  DEAL_TYPE,
  DISPLAY_PRICE,
  DRIVE_TRAIN,
  EXTERIOR_COLOR,
  FUEL_TYPE,
  HISTORY_INFO,
  INTERIOR_COLOR,
  INVENTORY_TYPE,
  LEASE_PAYMENT,
  LOAN_PAYMENT,
  MAKE,
  MILEAGE,
  MODEL,
  OPTION,
  RADIUS,
  TRANSMISSION,
  TRIM,
  YEAR,
} from 'site-modules/shared/constants/allowed-inventory-request-params';
import { getSrpBrowserQuery } from 'site-modules/shared/utils/inventory/srp-utils';
import { clone, head, omit, set } from 'lodash';

const NATIONWIDE_FACET_VALUE = '6000';
const ENGINE_MECHANICS_GROUP = [CYLINDERS_COUNT, TRANSMISSION, DRIVE_TRAIN, FUEL_TYPE];
const engineMechanicsGroupHandler = (facetName, facetValue, selectedFacets) => ({
  query: getSrpBrowserQuery(omit(selectedFacets, ENGINE_MECHANICS_GROUP)),
  text: 'Engine/mechanics - see all',
  trackingValue: 'search-any-engine-mechanics',
  icon: 'icon-cog7',
});
export const LINK_INFO_MAPPING = {
  [LEASE_PAYMENT]: {
    text: 'Increase price/payment',
    trackingValue: 'search-any-price-payment',
    icon: 'icon-price-tag',
  },
  [LOAN_PAYMENT]: {
    text: 'Increase price/payment',
    trackingValue: 'search-any-price-payment',
    icon: 'icon-price-tag',
  },
  [DISPLAY_PRICE]: {
    text: 'Increase price/payment',
    trackingValue: 'search-any-price-payment',
    icon: 'icon-price-tag',
  },
  [EXTERIOR_COLOR]: {
    text: 'Exterior colors - see all',
    trackingValue: 'search-any-exterior-color',
    icon: 'icon-circle-half',
  },
  [MODEL]: {
    text: 'Model - see all from this make',
    trackingValue: 'search-any-model',
    hiddenRule: (val, selectedFacets) => !selectedFacets[MAKE],
    icon: 'icon-car',
  },
  [MAKE]: {
    text: 'See all vehicles',
    trackingValue: 'search-any-make',
    handler: (facetName, facetValue, selectedFacets) => ({
      query: getSrpBrowserQuery(omit(selectedFacets, [MAKE, MODEL])),
    }),
    icon: 'icon-list',
  },
  [YEAR]: {
    text: 'Year - see all',
    trackingValue: 'search-any-model-year',
    icon: 'icon-calendar4',
  },
  [RADIUS]: {
    text: 'Increase search distance',
    hiddenRule: val => head(val) === NATIONWIDE_FACET_VALUE,
    handler: (facetName, facetValue, selectedFacets) => {
      const facets = clone(selectedFacets);
      const isNational = parseInt(head(facetValue), 10) >= 500;
      set(facets, facetName, isNational ? [NATIONWIDE_FACET_VALUE] : ['500']);
      return {
        query: isNational && !facets[MODEL] ? '' : getSrpBrowserQuery(facets),
        trackingValue: isNational ? 'expand-search-radius-national' : 'expand-search-radius-local',
      };
    },
    icon: 'icon-location',
  },
  [TRIM]: {
    text: 'Trim levels - include all',
    trackingValue: 'search-any-trim',
    icon: 'icon-compare_cars',
  },
  [CYLINDERS_COUNT]: {
    handler: engineMechanicsGroupHandler,
  },
  [TRANSMISSION]: {
    handler: engineMechanicsGroupHandler,
  },
  [DRIVE_TRAIN]: {
    handler: engineMechanicsGroupHandler,
  },
  [FUEL_TYPE]: {
    handler: engineMechanicsGroupHandler,
  },
  [MILEAGE]: {
    text: 'Increase mileage',
    trackingValue: 'search-any-mileage',
    icon: 'icon-meter',
  },
  [INTERIOR_COLOR]: {
    text: 'Interior colors - see all',
    trackingValue: 'search-any-interior-color',
    icon: 'icon-circle-half',
  },
  [DEAL_TYPE]: {
    text: 'Deal ratings - include all',
    trackingValue: 'search-any-deal-rating',
    icon: 'icon-star-full',
  },
  [HISTORY_INFO]: {
    text: 'Vehicle history - search all',
    trackingValue: 'search-any-vehicle-history',
    icon: 'icon-certificate1',
  },
  [OPTION]: {
    text: 'Options/packages - see all',
    trackingValue: 'search-any-options-packages',
    icon: 'icon-equalizer',
  },
  [BODY_TYPE]: {
    text: 'Vehicle types - search all',
    trackingValue: 'search-any-type',
    icon: 'icon-compare_cars',
  },
};

export const LINK_INFO_MAPPING_WITH_TYPE = {
  ...LINK_INFO_MAPPING,
  [INVENTORY_TYPE]: {
    text: 'See new, used and Certified Pre-owned (CPO)',
    hiddenRule: val => val.length === 3,
    trackingValue: 'search-any-condition',
    icon: 'icon-clipboard5',
  },
};
