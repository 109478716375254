import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { isRenderedAdUnit } from 'client/utils/ads';

/*
  Wrapper component for a SRP page that hide cards with empty ad units in the vehicle listing.
*/
export function SrpCardListAdWrapper({ children, dataTrackingParent, wrapperTag, wrapperClass }) {
  const [loaded, setLoaded] = useState(false);

  const slotRenderEndListener = useCallback(slotObj => {
    const isLoaded = isRenderedAdUnit(slotObj);
    setLoaded(isLoaded);
    if (isLoaded) {
      const slotWrapper = document.querySelector(`#${slotObj.slot.getSlotElementId()}`);
      slotWrapper.parentNode.style.display = 'block';
    }
  }, []);

  return (
    <Row
      tag={wrapperTag}
      className={classnames(wrapperClass, { 'd-none': !loaded })}
      {...(dataTrackingParent ? { 'data-tracking-parent': dataTrackingParent } : {})}
    >
      <Col>{React.Children.map(children, child => React.cloneElement(child, { slotRenderEndListener }))}</Col>
    </Row>
  );
}

SrpCardListAdWrapper.propTypes = {
  wrapperClass: PropTypes.string,
  wrapperTag: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]).isRequired,
  dataTrackingParent: PropTypes.string,
};

SrpCardListAdWrapper.defaultProps = {
  wrapperClass: '',
  wrapperTag: 'li',
  dataTrackingParent: null,
};
