import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { GlobalNavigation } from 'site-modules/shared/components/global-navigation-v2/global-navigation';
import { Footer } from 'site-modules/shared/components/footer/footer';
import { FeatureFlag } from 'site-modules/shared/components/feature-flag/feature-flag';
import { SpaLinks } from 'site-modules/shared/components/spa-links/spa-links';
import { ProfileContextAsync } from 'site-modules/shared/components/profile/profile-context-async';
import { ExperimentsPanel } from 'site-modules/shared/components/experiment/panel/experiments-panel';
import { AdsContext } from 'site-modules/shared/components/ads-context/ads-context';
import { ClientSideRender } from 'site-modules/shared/components/client-side-render/client-side-render';
import { ErrorBoundary } from 'site-modules/shared/components/error-boundary/error-boundary';
import { ErrorPageMessage } from 'site-modules/shared/components/error-page-message/error-page-message';
import { PubpixelAd } from 'site-modules/shared/components/pubpixel/pubpixel-ad';
import { ModelPreloader } from 'client/data/luckdragon/redux/model-preloader';
import { VisitorModel } from 'client/data/models/visitor';
import { SEOHead } from 'site-modules/shared/pages/seo';
import { FloodlightAd } from 'site-modules/shared/components/floodlight/floodlight-ad';
import { SkipToMainContent } from 'site-modules/shared/components/skip-to-main-content/skip-to-main-content';

class EdmundsCpaNoAdhesionDecoratorUI extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    skipFloodlight: PropTypes.bool,
    skipPubpixel: PropTypes.bool,
    showSearchBar: PropTypes.bool,
    isMobile: PropTypes.bool,
  };

  static defaultProps = {
    className: null,
    skipFloodlight: false,
    skipPubpixel: false,
    showSearchBar: true,
    isMobile: false,
  };

  static preload(store) {
    const preloader = new ModelPreloader(store);
    preloader.resolve('segment', VisitorModel);

    return preloader.load();
  }

  render() {
    const { children, isMobile, skipFloodlight, skipPubpixel, showSearchBar, className } = this.props;

    return (
      <div className={classnames('edm-page has-global-nav', className, { desktop: !isMobile })}>
        <ErrorBoundary>
          <SkipToMainContent />
          <GlobalNavigation showSearchBar={showSearchBar} />
        </ErrorBoundary>
        <ErrorBoundary>
          <SEOHead {...this.props} />
        </ErrorBoundary>
        <ErrorBoundary>
          <FeatureFlag name="testSpa">
            <SpaLinks />
          </FeatureFlag>
        </ErrorBoundary>
        <ErrorBoundary message={ErrorPageMessage}>{children}</ErrorBoundary>
        <ErrorBoundary>
          <Footer />
        </ErrorBoundary>
        <ErrorBoundary>
          <FeatureFlag name="ads">
            {!skipFloodlight && (
              <ErrorBoundary>
                <FloodlightAd />
              </ErrorBoundary>
            )}
            {!skipPubpixel && (
              <ErrorBoundary>
                <PubpixelAd />
              </ErrorBoundary>
            )}
            <ErrorBoundary>
              <AdsContext />
            </ErrorBoundary>
          </FeatureFlag>
        </ErrorBoundary>
        <ErrorBoundary>
          <FeatureFlag name="insider">
            <ProfileContextAsync />
          </FeatureFlag>
        </ErrorBoundary>
        <ErrorBoundary>
          <ClientSideRender>
            <ExperimentsPanel />
          </ClientSideRender>
        </ErrorBoundary>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isMobile: state.mobile,
});

export const EdmundsCpaNoAdhesionDecorator = connect(mapStateToProps)(EdmundsCpaNoAdhesionDecoratorUI);
