import { TrackingEvent } from 'client/tracking/event';
import { TrackingHelper } from 'client/tracking/maps/helper';
import { CommonMap } from 'client/tracking/maps/common';
import { DealerMap } from 'client/tracking/maps/common/dealer';
import { TrackingConstant } from 'client/tracking/constant';
import { CompareVehicleTrayMap } from 'client/tracking/maps/common/compare-vehicle-tray';
import { CalculatorMap } from 'client/tracking/maps/calculator';
import { IncentivesWizardMap } from 'client/tracking/maps/incentives-wizard';

const map = {
  srp_view_vin_details(options) {
    return TrackingEvent.actionCompleted(
      TrackingConstant.ACTION_VIEW_VIN_DETAILS,
      TrackingConstant.ACTION_VIEW_VIN_DETAILS,
      TrackingHelper.getBasicDealerOptions(options)
    );
  },
  save_vehicle(options) {
    return TrackingEvent.actionCompleted(
      'save_vehicle',
      'save_vehicle',
      TrackingHelper.getBasicDealerOptions(options, true)
    );
  },
  inventory_view_filter_search_result: TrackingEvent.actionCompleted(
    TrackingConstant.VIEW_SEARCH_RESULT,
    TrackingConstant.FILTER_SEARCH_RESULT
  ),
  inventory_remove_filter_search_result: TrackingEvent.actionCompleted(
    TrackingConstant.VIEW_SEARCH_RESULT,
    TrackingConstant.FILTER_SEARCH_RESULT_REMOVE
  ),
  view_more_info: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, TrackingConstant.VIEW_MORE_INFO),
  view_vin_details: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_VIN_DETAILS,
    TrackingConstant.ACTION_VIEW_VIN_DETAILS
  ),
  view_cpo_reqs: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.ACTION_VIEW_CPO_REQS
  ),
  close: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, TrackingConstant.ACTION_CLOSE),
  select_sort_by: TrackingEvent.actionCompleted(
    TrackingConstant.VIEW_SEARCH_RESULT,
    TrackingConstant.SORT_SEARCH_RESULT
  ),
  view_trim_srp: TrackingEvent.actionCompleted(
    TrackingConstant.CLICK_TO_HELP_ME_CHOOSE,
    TrackingConstant.VIEW_TRIM_SRP
  ),
  reviews_specs: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, TrackingConstant.REVIEW_SPECS),
  write_review: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.ACTION_WRITE_REVIEW
  ),
  read_all_reviews: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.READ_ALL_REVIEWS
  ),
  view_consumer_review: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.VIEW_CONSUMER_REVIEW
  ),
  vin_sign_up_progress: TrackingEvent.actionStart(TrackingConstant.ACTION_SIGN_UP, TrackingConstant.BEGIN_SIGN_UP),
  vin_save_vehicle(options) {
    return TrackingEvent.actionCompleted(TrackingConstant.SAVE_VEHICLE, TrackingHelper.getSubactionName(options));
  },
  srp_sign_up_progress: TrackingEvent.actionStart(TrackingConstant.ACTION_SIGN_UP, TrackingConstant.BEGIN_SIGN_UP),
  srp_save_search: TrackingEvent.actionCompleted(TrackingConstant.SAVE_VEHICLE, TrackingConstant.SAVE_SEARCH),
  srp_unsave_search: TrackingEvent.actionCompleted(TrackingConstant.SAVE_VEHICLE, TrackingConstant.UNSAVE_SEARCH),

  srp_provide_email: TrackingEvent.actionStart(TrackingConstant.ACTION_SIGN_UP, TrackingConstant.PROVIDE_EMAIL),
  srp_dismiss: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_RESET_NAVIGATION,
    TrackingConstant.NAVIGATION_CLOSE
  ),
  show_link: TrackingEvent.actionCompleted(TrackingConstant.ACTION_SHOW_CONTENT, TrackingConstant.ACTION_SHOW_LINK, {
    action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
  }),
  display_order: TrackingEvent.actionCompleted(TrackingConstant.ACTION_SHOW_CONTENT, TrackingConstant.DISPLAY_ORDER),
  view_lease_evaluation: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_TOOL_SELECT,
    TrackingConstant.VIEW_LEASE_EVALUATION
  ),
  editorial_filter_card_click: TrackingEvent.actionCompleted(
    TrackingConstant.VIEW_SEARCH_RESULT,
    TrackingConstant.FILTER_SEARCH_RESULT
  ),
  vdp_quick_view_photo: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_PHOTO,
    TrackingConstant.ACTION_VIEW_PHOTO
  ),
  view_search_result_online: TrackingEvent.actionCompleted(
    TrackingConstant.VIEW_SEARCH_RESULT,
    TrackingConstant.VISIT_BUY_ONLINE_CHANNEL
  ),
  view_search_result_in_store: TrackingEvent.actionCompleted(
    TrackingConstant.VIEW_SEARCH_RESULT,
    TrackingConstant.LEAVE_BUY_ONLINE_CHANNEL
  ),
  view_loan_pricing: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.VIEW_LOAN_PRICING
  ),
  view_lease_pricing: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.VIEW_LEASE_PRICING
  ),
  view_purchase_pricing: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.VIEW_PURCHASE_PRICING
  ),
  toggle_financing: TrackingEvent.actionCompleted(
    TrackingConstant.VIEW_SEARCH_RESULT,
    TrackingConstant.FILTER_SEARCH_RESULT
  ),
  close_prequal_approved_message: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.CLOSE
  ),
  drawer_close: TrackingEvent.actionCompleted(TrackingConstant.ACTION_RESET_NAVIGATION, TrackingConstant.CLOSE_DRAWER),
  view_filters: TrackingEvent.actionCompleted(TrackingConstant.ACTION_VIEW_CONTENT, TrackingConstant.VIEW_FILTERS),
  display_incentive_program_details: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.DISPLAY_INCENTIVE_PROGRAM_DETAILS
  ),
  check_availability: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_PROVIDE_ZIP_CODE,
    TrackingConstant.CHECK_AVAILABILITY
  ),
  search: TrackingEvent.actionCompleted(TrackingConstant.ACTION_PROVIDE_ZIP_CODE, TrackingConstant.SEARCH),
  see_other_years: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.SEE_OTHER_YEARS
  ),
  view_features_specs: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.VIEW_FEATURES_SPECS
  ),
  view_filter_section: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.VIEW_FILTER_SECTION
  ),
  close_filter_section: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_VIEW_CONTENT,
    TrackingConstant.CLOSE_FILTER_SECTION
  ),
};

export const TrackingMap = {
  ...CommonMap,
  ...CalculatorMap,
  ...DealerMap,
  ...CompareVehicleTrayMap,
  ...IncentivesWizardMap,
  ...map,
};
