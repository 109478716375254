import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { numberWithCommas } from 'site-modules/shared/utils/string';
import { SrpSortBy } from 'site-modules/shared/components/inventory/srp-sort-by/srp-sort-by';
import { SrpSaveSearch } from 'site-modules/shared/components/inventory/srp-save-search/srp-save-search';
import { UsurpSaveSearch } from 'site-modules/shared/components/inventory/usurp-save-search/usurp-save-search';

export const SrpCardsTopPanel = memo(({ inventoryCount, selectedFacets, onUpdate, className, withRange, isMobile }) => (
  <div className={classNames('d-flex justify-content-between', className)}>
    <span className="size-14 text-cool-gray-40" aria-live="polite" role="status" aria-atomic>
      {numberWithCommas(inventoryCount || 0)} listings
    </span>
    <div className="d-flex">
      <SrpSortBy onUpdate={onUpdate} selectedFacets={selectedFacets} withRange={withRange} />
      {!isMobile && (
        <>
          <div className="save-search-separate mx-1" />
          <SrpSaveSearch CustomView={UsurpSaveSearch} showModal />
        </>
      )}
    </div>
  </div>
));

SrpCardsTopPanel.displayName = 'SrpCardsTopPanel';

SrpCardsTopPanel.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  selectedFacets: PropTypes.shape({}),
  inventoryCount: PropTypes.number,
  className: PropTypes.string,
  withRange: PropTypes.bool,
  isMobile: PropTypes.bool,
};
SrpCardsTopPanel.defaultProps = {
  className: 'mb-1',
  inventoryCount: null,
  isMobile: false,
  withRange: false,
  selectedFacets: {},
};
