import React from 'react';
import { connect } from 'react-redux';
import { connectToModel, bindToPath } from 'client/data/luckdragon/redux/react-binding';
import { PageModel } from 'client/data/models/page';
import {
  AdComponentPropTypes,
  AdComponentDefaultProps,
} from 'client/site-modules/shared/components/ad-unit/ad-unit-prop-types';
import { SRP_AD } from 'site-modules/shared/components/native-ad/utils/constants';
import { SRPSiteServedRetention } from 'site-modules/shared/components/native-ad/srp-site-served-retention/srp-site-served-retention';
import { UsurpSiteServedAd } from 'site-modules/shared/components/native-ad/usurp-site-served-ad/usurp-site-served-ad';
import PropTypes from 'prop-types';

export function UsurpAdUI(props) {
  return (
    <SRPSiteServedRetention
      adType={SRP_AD}
      siteServedComponent={UsurpSiteServedAd}
      wrapperTag="li"
      wrapperTagClassName={props.wrapperTagClassName}
      wrapperAdComponentClassName="usurp-inventory-card rounded-12 h-100 w-100 bg-white"
      {...props}
    />
  );
}

UsurpAdUI.propTypes = {
  ...AdComponentPropTypes,
  wrapperTagClassName: PropTypes.string,
};

UsurpAdUI.defaultProps = {
  ...AdComponentDefaultProps,
  position: '1',
  wrapperTagClassName: 'mb-0_75 mb-md-1_5 col-12 col-md-6',
};

const mapStateToProps = state => ({
  mobile: state.mobile,
});

export const stateToPropsConfig = {
  vehicle: bindToPath('vehicle', PageModel),
};

export const UsurpAd = connect(mapStateToProps)(connectToModel(UsurpAdUI, stateToPropsConfig));
